<script>
export default {
    data() {
        return {}
    },
    methods: {
        performancePercentageToColor(value) {
            if (!value || value === undefined || value === null || typeof value !== 'number') {
                return '#AF0404'
            }
            else if (value >= 90)  {
                return '#64BB77'
            }
            else if (value >= 60 && value < 90)  {
                return '#32BDF9'
            }
            else if (value >= 40 && value < 60)  {
                return '#EDB012'
            }
            else if (value >= 1 && value < 40)  {
                return '#ED6112'
            }

            return '#AF0404'
        },
        performanceNoteToColor(value) {
            if (!value || value === undefined || value === null) {
                return '#AF0404'
            }
            else if (value === 'A')  {
                return '#64BB77'
            }
            else if (value === 'B')  {
                return '#32BDF9'
            }
            else if (value === 'C')  {
                return '#EDB012'
            }
            else if (value === 'D')  {
                return '#ED6112'
            }

            return '#AF0404'
        },
    },
}
</script>
