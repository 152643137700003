<template>
    <div class="card card-pdv performance-card">
        <div class="card-body">
            <b-row v-if="busy">
                <b-col md="12">
                    <b-skeleton type="button" width="100%" height="60px"></b-skeleton>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col md="5" class="align-self-end pr-0">
                    <b-row class="justify-content-center">
                        <label class="tag-text" :style="tagTextStyle">
                            {{ tag }}
                        </label>
                    </b-row>
                    <b-row class="justify-content-center text-center">
                        <label class="range-text">
                            {{ i18nEffectiveness }} <br> {{ range }}
                        </label>
                    </b-row>
                </b-col>
                <b-col md="7" class="text-center">
                    <h5 class="title">{{ title }}</h5>
                    <label class="main-text">
                        {{ padLeft(value, 4) }}
                    </label>
                    <div class="progress mt-2">
                        <b-progress-bar
                            :value="value"
                            :max="total"
                            :style="`background-color: ${textColor}`"
                            class="progress-pdv">
                        </b-progress-bar>
                    </div>
                    <label class="percentage-text" :style="tagTextStyle">
                        {{ total > 0 ? Math.round(value / total * 100) : 0 }}%
                    </label>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import vuetableFormatters from '@src/mixins/vuetableFormatters'

const i18nCommon = 'COMMON'

export default {
    components: {},
    mixins: [
        vuetableFormatters,
    ],
    props: {
        title: {
            type: String,
            required: true,
        },
        range: {
            type: String,
            required: true,
        },
        tag: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        total: {
            type: Number,
            required: false,
            default: 0,
        },
        textColor: {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            i18nCommon,
        }
    },
    computed: {
        tagTextStyle() {
            return {
                '--color': this.textColor
            }
        },
        i18nEffectiveness() {
            return this.getI18n(i18nCommon, 'effectiveness')
        },
    },
}
</script>
<style>
.performance-card {
    width: 19%;
}

.performance-card .title {
    letter-spacing: 2%;
    font-family: 'Exo2-Regular', sans-serif;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.02em;
    color: #404249;
    margin-top: 10px;
}

.performance-card .main-text {
    color: #66686D;
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 120%;
    letter-spacing: 0.05em;
}

.performance-card .tag-text {
    color: var(--color);
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 120%;
    letter-spacing: 0.02em;
    margin-bottom: 0;
}

.performance-card .range-text {
    color: #66686D;
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 120%;
    letter-spacing: 0.02em;
    margin-bottom: 0;
}

.performance-card .percentage-text {
    color: var(--color);
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
    margin-bottom: 0;
    display: inline-block;
    width: 100%;
    text-align: right;
}
</style>
