<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nPunctualVision" />
        <div class="card-body">
            <stacked-column-full-chart
                :series-prop="series"
                :categories-series-prop="categories"
                :busy="loading"
                :colors-prop="['#64BB77', '#32BDF9', '#EDB012', '#ED6112', '#AF0404']"
            />
        </div>
    </div>
</template>

<script>
import PosPerformanceService from '@src/services/dashboards/PosPerformanceService.js'

import PageHeader from '@/src/components/PageHeader.vue'
import StackedColumnFullChart from '@/src/components/charts/StackedColumnFullChart.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        PageHeader,
        StackedColumnFullChart,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            additionalParameters: {},
            loading: false,
            series: [],
            categories: [],
        }
    },
    validations: {},
    computed: {
        i18nPunctualVision() {
            return this.getI18n(i18nKey, 'TITLES.punctual_vision')
        },
        i18nWorkSchedule() {
            return this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule')
        },
        i18nQuestionnaire() {
            return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
        },
        i18nTimeInStore() {
            return this.getI18n('POS_CHECK', 'FIELDS.time_in_store')
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.series = []
            this.categories = [
                this.i18nWorkSchedule,
                this.i18nQuestionnaire,
                this.i18nTimeInStore,
            ]
            this.additionalParameters = parameters

            PosPerformanceService.statics(this.additionalParameters)
                .then((response) => {
                    // eslint-disable-next-line camelcase
                    const {  work_schedule, task, time_in_store } = response.data.data

                    this.series = [
                        {
                            name: '90%-100%',
                            data: [
                                work_schedule.A,
                                task.A,
                                time_in_store.A,
                            ]
                        },
                        {
                            name: '60%-89%',
                            data: [
                                work_schedule.B,
                                task.B,
                                time_in_store.B,
                            ]
                        },
                        {
                            name: '40%-59%',
                            data: [
                                work_schedule.C,
                                task.C,
                                time_in_store.C,
                            ]
                        },
                        {
                            name: '1%-39%',
                            data: [
                                work_schedule.D,
                                task.D,
                                time_in_store.D,
                            ]
                        },
                        {
                            name: '0%',
                            data: [
                                work_schedule.E,
                                task.E,
                                time_in_store.E,
                            ]
                        },
                    ]



                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
