<template>
    <form-modal
        ref="formModal"
        :v="$v.adjustScoreModal"
        :title="i18nAdjustScore"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nAdjustScore"
                    :operation="'edited'"
                />
                <b-row>
                    <b-col md="12">
                        <topic-header
                            :caption="i18nWorkSchedule"
                            icon="fe-list"
                        />
                        <b-form-group
                            :label="getI18n(i18nKey, 'PERFORMANCE.points_if_complete')"
                            label-for="workschedule-score"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                            <b-form-input
                                id="workschedule-score"
                                v-model.number="$v.adjustScoreModal.workschedule_score.$model"
                                class="input-pdv-blue"
                                type="number"
                                min="0"
                                max="100"
                                autofocus
                                autocomplete="off"
                                :disabled="loading || submitLoading"
                                :state="validateField('workschedule_score', 'adjustScoreModal')"
                                @input="clearResponseError('workschedule_score', 'adjustScoreModal')"
                                @blur="$v.adjustScoreModal.workschedule_score.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <topic-header
                            :caption="i18nQuestionnaire"
                            icon="fe-file-text"
                        />
                        <b-form-group
                            :label="getI18n(i18nKey, 'PERFORMANCE.points_if_complete')"
                            label-for="questionnaire-score"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                            <b-form-input
                                id="questionnaire-score"
                                v-model.number="$v.adjustScoreModal.questionnaire_score.$model"
                                class="input-pdv-blue"
                                type="number"
                                min="0"
                                max="100"
                                autocomplete="off"
                                :disabled="loading || submitLoading"
                                :state="validateField('questionnaire_score', 'adjustScoreModal')"
                                @blur="$v.adjustScoreModal.questionnaire_score.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <topic-header
                            :caption="i18nTimeInStore"
                            icon="fe-shopping-cart"
                        />
                        <b-form-group
                            :label="getI18n(i18nKey, 'PERFORMANCE.points_if_it_stays_longer')"
                            label-for="time-in-store-score"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_score')">
                            <b-form-input
                                id="time-in-store-score"
                                v-model.number="$v.adjustScoreModal.time_in_store_score.$model"
                                class="input-pdv-blue"
                                type="number"
                                min="0"
                                max="100"
                                autocomplete="off"
                                :disabled="loading || submitLoading"
                                :state="validateField('time_in_store_score', 'adjustScoreModal')"
                                @blur="$v.adjustScoreModal.time_in_store_score.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <cancel-button
                ref="cancelButton"
                child-class="col-sm-4 float-left"
                :disabled="loading || submitLoading"
                @onClick="handleCancel"
            />
            <save-button
                ref="okButton"
                child-class="col-sm-4 float-right ml-3"
                :busy="submitLoading"
                :disabled="loading"
                @onClick="handleOk"
            />
        </template>
    </form-modal>
</template>

<script>
import PosPerformanceConfig from '@src/services/PosPerformanceConfig.js'

import {
    required,
    minValue,
    maxValue,
    integer,
} from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback'
import validationMixin from '@src/mixins/validation'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import CancelButton from '@/src/components/CancelButton'
import SaveButton from '@/src/components/SaveButton'
import TopicHeader from '@src/components/TopicHeader.vue'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        CancelButton,
        SaveButton,
        ConfirmationFormModal,
        TopicHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: true,
            submitLoading: false,
            adjustScoreModal: {
                workschedule_score: 0,
                questionnaire_score: 0,
                time_in_store_score: 0,
            }
        }
    },
    validations: {
        adjustScoreModal: {
            workschedule_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            questionnaire_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
            time_in_store_score: {
                required,
                integer,
                minValue: minValue(0),
                maxValue: maxValue(100),
            },
        },
    },
    computed: {
        i18nAdjustScore() {
            return this.getI18n(i18nCommon, 'adjust_score')
        },
        i18nPunctuality() {
            return this.getI18n(i18nCommon, 'punctuality')
        },
        i18nWorkSchedule() {
            return this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule')
        },
        i18nQuestionnaire() {
            return this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire')
        },
        i18nTimeInStore() {
            return this.getI18n('POS_CHECK', 'FIELDS.time_in_store')
        },
        i18nCheckInDistance() {
            return this.getI18n(i18nCommon, 'checkin_distance')
        },
        i18nCheckOutDistance() {
            return this.getI18n(i18nCommon, 'checkout_distance')
        },
        i18nBattery() {
            return this.getI18n('POS_CHECK', 'FIELDS.battery')
        },
        i18nMinutes() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'minutes',
                modifier: 2,
            }).toLowerCase()
        },
        i18nMeters() {
            return this.getI18nModified({
                prefix: i18nCommon,
                suffix: 'meter',
                modifier: 2,
            }).toLowerCase()
        },
    },
    methods: {
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        showModal() {
            this.submitLoading = false
            this.loadInformation()
            this.$refs.formModal.show()
            this.resetValidation()
        },
        handleCancel() {
            this.$refs.confirmationFormModal.showModal(true, this.handleClose)
        },
        handleClose(close) {
            if (!close) {
                return
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        handleOk() {
            this.$v.adjustScoreModal.$touch()
            if (!this.$v.adjustScoreModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },
        async handleSubmit(submit) {
            if (!submit) {
                return
            }

            this.submitLoading = true

            const response = await PosPerformanceConfig.create(this.adjustScoreModal)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nAdjustScore, 'edited')
            }

            if (statusCode === '417') {
                this.operationUnsuccessful(i18nKey, `ERRORS.${response.data.message}`, 'edited')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }
            this.submitLoading = false
        },
        loadInformation() {
            this.loading = true

            PosPerformanceConfig.fetchAll()
                .then((response) => {
                    this.loading = false
                    if (response.data.data) {
                        this.adjustScoreModal = response.data.data
                    } else {
                        this.adjustScoreModal = {
                            workschedule_score: 10,
                            questionnaire_score: 10,
                            time_in_store_score: 10,
                        }
                    }
                })
                .catch((error) => {
                    this.loading = false
                    this.errorFeedback(error)
                })
        }
    },
}
</script>
