import BaseService from '@src/services/BaseService'

class PosPerformanceService extends BaseService {
    constructor() {
        super('pos-performance')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }

    statics(params) {
        return this.axios.post(`${this.getUrl()}/statics`, params)
    }
}

export default new PosPerformanceService()

