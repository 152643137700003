<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nChain"
                                class="label-pdv"
                                label-for="filter-chain"
                            >
                                <multiSelectWithService
                                    :id="'filter-chain'"
                                    ref="chainMultiselect"
                                    v-model="filters.chains"
                                    :service="'pos-chains'"
                                    :searchable="true"
                                    :multiple="true"
                                    :parameters="filterParameters"
                                    :disabled="loading"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <b-row class="mb-2">
                <div class="col-sm-12">
                    <div class="text-sm-right">
                        <adjust-score-label-button @onClick="showAdjustScoreModal" @refresh="refresh" />
                    </div>
                </div>
            </b-row>
        </div>
        <div>
            <b-row>
                <b-col md="12">
                    <pos-performance-resume ref="posPerformanceResume" />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <pos-performance-chart ref="posPerformanceChart" />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <div class="card card-pdv">
                        <PageHeader :title="i18nPerformance" />
                        <div class="card-body">
                            <api-mode-vuetable
                                ref="vuetable"
                                :fetch-data="fetch"
                                :fields="fields"
                                :per-page="perPage"
                                :display-selection-records-per-page="true"
                                :additional-parameters="additionalParameters"
                                :sort-order="sortOrder">
                                <template slot="perc_total_point" slot-scope="props">
                                    <b-badge
                                        pill
                                        :style="`background-color: ${performancePercentageToColor(props.rowData.perc_total_point)};`"
                                    >
                                        {{ formatPercentageInteger(props.rowData.perc_total_point) }}
                                    </b-badge>
                                </template>
                                <template slot="pos_grade" slot-scope="props">
                                    <label
                                        :style="`color: ${performanceNoteToColor(props.rowData.pos_grade)};`"
                                    >
                                        {{ props.rowData.pos_grade }}
                                    </label>
                                </template>
                            </api-mode-vuetable>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <pos-performance-filter-modal ref="posPerformanceFilterModal" :handle-submit="filter" />
        <pos-adjust-score-modal ref="posAdjustScoreModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import PosPerformanceService from '@src/services/dashboards/PosPerformanceService.js'

import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Layout from '@layouts/main'
import PageHeader from '@/src/components/PageHeader.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import AdjustScoreLabelButton from '@/src/components/AdjustScoreLabelButton'
import FilterButton from '@/src/components/FilterButton'
import posPerformanceChart from '@src/router/views/dashboards/performance/pos/posPerformanceChart.vue'
import posPerformanceFilterModal from '@/src/router/views/dashboards/performance/pos/posPerformanceFilterModal.vue'
import posPerformanceResume from '@src/router/views/dashboards/performance/pos/posPerformanceResume.vue'
import posAdjustScoreModal from '@src/router/views/dashboards/performance/pos/posAdjustScoreModal.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import performance from '@src/mixins/performance'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        ApiModeVuetable,
        Layout,
        PageHeader,
        Breadcrumb,
        DateRangePicker,
        multiSelectWithService,
        MoreFiltersLabelButton,
        AdjustScoreLabelButton,
        FilterButton,
        posPerformanceChart,
        posPerformanceFilterModal,
        posPerformanceResume,
        posAdjustScoreModal,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter,
        performance,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            perPage: 10,
            additionalParameters: this.createAdditionalParameters(),
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                point_of_sales: [],
                regionals: [],
                chains: [],
            },
            sortOrder: [
                {
                    field: 'perc_total_point',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'pos_name',
                    title: this.getI18n('EXPORT_PRESENTATION_JOBS', 'TITLES.pos_alias'),
                    sortField: 'pos_name',
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                    sortField: 'chain_name',
                },
                {
                    name: 'regional_name',
                    title: this.getI18n('POS', 'TITLES.regional'),
                    sortField: 'regional_name',
                },
                {
                    name: 'category_name',
                    title: this.getI18n('POS', 'TITLES.category'),
                    sortField: 'category_name',
                },
                {
                    name: 'last_checkin',
                    title: this.getI18n(i18nCommon, 'last_visit'),
                    sortField: 'last_checkin',
                    // formatter: this.formatDate,
                },
                // {
                //     name: 'status',
                //     title: this.getI18n(i18nCommon, 'status'),
                //     sortField: 'status',
                // },
                {
                    name: 'perc_work_schedule_point',
                    title: this.getI18n('WORKSCHEDULES', 'TITLES.work_schedule'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_work_schedule_point',
                },
                {
                    name: 'perc_task_point',
                    title: this.getI18n('QUESTIONNAIRES', 'TITLES.questionnaire'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_task_point',
                },
                {
                    name: 'perc_time_in_store_point',
                    title: this.getI18n('POS_CHECK', 'FIELDS.time_in_store'),
                    formatter: this.formatPercentageInteger,
                    sortField: 'perc_time_in_store_point',
                },
                {
                    name: 'total_point',
                    title: this.getI18n(i18nCommon, 'punctuation'),
                    sortField: 'total_point',
                },
                {
                    name: 'perc_total_point',
                    title: this.getI18n(i18nCommon, 'effectiveness'),
                    sortField: 'perc_total_point',
                },
                {
                    name: 'pos_grade',
                    title: this.getI18n(i18nCommon, 'note'),
                    sortField: 'pos_grade',
                },
                // {
                //     name: 'actions',
                //     title: '',
                // },
            ],
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.i18nPosPerformance,
                    active: true,
                },
            ]
        },
        i18nPosPerformance() {
            return this.getI18n(i18nKey, 'TITLES.performance_pos')
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nPerformance() {
            return this.getI18n(i18nKey, 'TITLES.performance')
        },
    },
    mounted() {
        this.filter()
    },
    methods: {
        createAdditionalParameters() {
            if (!this.filters) {
                this.filters = {
                    date: {
                        start: moment().startOf('day').add(-30, 'days').toDate(),
                        end: moment().startOf('day').toDate()
                    },
                    point_of_sales: [],
                    regionals: [],
                    chains: [],
                }
            }

            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                point_of_sale_id: this.filters.point_of_sales.map((pos) => pos.id),
                regional_id: this.filters.regionals.map((regional) => regional.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            if (appliedFilters) {
                this.filters = appliedFilters
            }
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.posPerformanceFilterModal.showModal(this.filters))
        },
        showAdjustScoreModal() {
            this.$nextTick(() => this.$refs.posAdjustScoreModal.showModal())
        },
        fetch(url, params) {
            return PosPerformanceService.fetchVuetable(url, params)
        },
        refresh() {
            this.loading = true

            this.$refs.posPerformanceResume.refresh(this.additionalParameters)
            this.$refs.posPerformanceChart.refresh(this.additionalParameters)
            this.refreshTable()

            this.loading = false
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    },
}
</script>
